import { apiSlice } from '../auth/apiSlice';

export const marketplaceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getItemListings: builder.query({
      query: () => '/market/items',
    }),
    addItemListing: builder.mutation({
      query: (body) => ({
        url: '/market/items',
        method: 'POST',
        body,
      }),
    }),
    getPropertyListings: builder.query({
      query: () => '/market/properties',
    }),
    addPropertyListing: builder.mutation({
      query: (body) => ({
        url: '/market/properties',
        method: 'POST',
        body,
      }),
    }),
    getInsuranceListings: builder.query({
      query: () => '/market/insurance',
    }),
    addInsuranceListing: builder.mutation({
      query: (body) => ({
        url: '/market/insurance',
        method: 'POST',
        body,
      }),
    }),
    getPointListings: builder.query({
      query: () => '/market/points',
    }),
    addPointListing: builder.mutation({
      query: (body) => ({
        url: '/market/points',
        method: 'POST',
        body,
      }),
    }),
    getLockpickListings: builder.query({
      query: () => '/market/lockpicks',
    }),
    addLockpickListing: builder.mutation({
      query: (body) => ({
        url: '/market/lockpicks',
        method: 'POST',
        body,
      }),
    }),
    buyItem: builder.mutation({
      query: (id) => ({
        url: `/market/buy/${id}`,
        method: 'POST',
      }),
    }),
    getBarItems: builder.query({
      query: () => '/market/bar-items',
    }),

    sendBarItem: builder.mutation({
      query: (body) => ({
        url: '/market/send-bar-item',
        method: 'POST',
        body,
      }),
    }),

    getGarageVehicles: builder.query({
      query: () => '/market/garage',
    }),
    shipVehicle: builder.mutation({
      query: ({ uuid, destination }) => ({
        url: '/market/garage/ship',
        method: 'POST',
        body: { uuid, destination },
      }),
    }),
    sendVehicle: builder.mutation({
      query: ({ uuid, recipient }) => ({
        url: '/market/garage/send',
        method: 'POST',
        body: { uuid, recipient },
      }),
    }),
    repairVehicle: builder.mutation({
      query: ({ uuid }) => ({
        url: '/market/garage/repair',
        method: 'POST',
        body: { uuid },
      }),
    }),
    scrapVehicle: builder.mutation({
      query: ({ uuid }) => ({
        url: '/market/garage/scrap',
        method: 'POST',
        body: { uuid },
      }),
    }),






    getMarineVehicles: builder.query({
      query: () => '/market/marina',
    }),
    shipMarineVehicle: builder.mutation({
      query: ({ uuid, destination }) => ({
        url: '/market/marina/ship',
        method: 'POST',
        body: { uuid, destination },
      }),
    }),
    sendMarineVehicle: builder.mutation({
      query: ({ uuid, recipient }) => ({
        url: '/market/marina/send',
        method: 'POST',
        body: { uuid, recipient },
      }),
    }),
    repairMarineVehicle: builder.mutation({
      query: ({ uuid }) => ({
        url: '/market/marina/repair',
        method: 'POST',
        body: { uuid },
      }),
    }),
    scrapMarineVehicle: builder.mutation({
      query: ({ uuid }) => ({
        url: '/market/marina/scrap',
        method: 'POST',
        body: { uuid },
      }),
    }),

    













  }),
});

export const {
  useGetItemListingsQuery,
  useAddItemListingMutation,
  useGetPropertyListingsQuery,
  useAddPropertyListingMutation,
  useGetInsuranceListingsQuery,
  useAddInsuranceListingMutation,
  useGetPointListingsQuery,
  useAddPointListingMutation,
  useGetLockpickListingsQuery,
  useAddLockpickListingMutation,
  useBuyItemMutation,
  useGetBarItemsQuery,
  useSendBarItemMutation,




  useGetGarageVehiclesQuery,
  useShipVehicleMutation,
  useSendVehicleMutation,
  useRepairVehicleMutation,
  useScrapVehicleMutation,


  useGetMarineVehiclesQuery,
  useShipMarineVehicleMutation,
  useSendMarineVehicleMutation,
  useRepairMarineVehicleMutation,
  useScrapMarineVehicleMutation,
  
  

} = marketplaceApi;
