import React, { useState, useEffect } from 'react';
import { useGetUpdatesQuery, useGetGameStatsQuery, useGetUserStatsQuery, useGetArticlesQuery, useCreateArticleMutation } from '../../api/general';
import './dashboard.css';
import ReactQuill from 'react-quill'; // You'll need to install this package
import 'react-quill/dist/quill.snow.css';

function GameUpdates() {
  const [fetchUpdates, setFetchUpdates] = useState(false);
  const { data: updates, isLoading, error } = useGetUpdatesQuery(undefined, {
    skip: !fetchUpdates,
  });

  useEffect(() => {
    setFetchUpdates(true);
  }, []);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <h3 className="mt-10px">Updates</h3>
      <table className="table">
        <thead>
          <tr>
            <th>Posted by</th>
            <th>Update Title</th>
            <th>Update Description</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(updates) && updates.length > 0 ? (
            updates.map((update) => (
              <tr key={update.uuid}>
                <td>{update.Users_character.username}</td>
                <td>{update.update}</td>
                <td>{update.description}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3}>No updates available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

function GameStats({ activeStatTab, setActiveStatTab }) {
  const { data: gameStats, isLoading: statsLoading, error: statsError } = useGetGameStatsQuery();
  if (statsLoading) return <p>Loading game stats...</p>;
  if (statsError) return <p>Error loading game stats: {statsError.message}</p>;


  const handleStatTabClick = (tabName) => {
    setActiveStatTab(tabName);
  };
  
  return (
    <div>
      <h3 className="mt-10px">Game Stats</h3>
      <ul className="nav nav-pills nav-justified mb-3">
        <li className="nav-item">
          <a href="#user-stats" onClick={() => handleStatTabClick('userStats')} className={`nav-link ${activeStatTab === 'userStats' ? 'active' : ''}`}>
            User Stats
          </a>
        </li>
        <li className="nav-item">
          <a href="#money-stats" onClick={() => handleStatTabClick('moneyStats')} className={`nav-link ${activeStatTab === 'moneyStats' ? 'active' : ''}`}>
            Money Stats
          </a>
        </li>
      </ul>
      <div className="tab-content">
        <div className={`tab-pane fade ${activeStatTab === 'userStats' ? 'show active' : ''}`} id="user-stats">
          <table className="table">
            <tbody>
              <tr><td>Total Users:</td><td>{gameStats.usersTotal}</td></tr>
              <tr><td>Alive Users:</td><td>{gameStats.usersAlive}</td></tr>
              <tr><td>Dead Users:</td><td>{gameStats.usersDead}</td></tr>
              <tr><td>Banned Users:</td><td>{gameStats.usersBanned}</td></tr>
              <tr><td>Active Users:</td><td>{gameStats.usersActive}</td></tr>
              <tr><td>Active Staff:</td><td>{gameStats.usersActiveStaff}</td></tr>
              <tr><td>Kill Attempts:</td><td>{gameStats.usersAttempts}</td></tr>
              <tr><td>Suicides:</td><td>{gameStats.usersSuicides}</td></tr>
            </tbody>
          </table>
        </div>
        <div className={`tab-pane fade ${activeStatTab === 'moneyStats' ? 'show active' : ''}`} id="money-stats">
          <table className="table">
            <tbody>
              <tr><td>Total on Hand:</td><td>£{gameStats.moneyUsers}</td></tr>
              <tr><td>Total in Bank:</td><td>£{gameStats.moneyBank}</td></tr>
              <tr><td>Total in Families:</td><td>£{gameStats.moneyFamily}</td></tr>
              <tr><td>Total in ATM:</td><td>£{gameStats.moneyAtm}</td></tr>
              <tr><td>Total in Savings:</td><td>£{gameStats.moneySavings}</td></tr>
              <tr><td>Total in Syndicates:</td><td>£{gameStats.moneySyndicates}</td></tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

function News() {
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showNewArticleModal, setShowNewArticleModal] = useState(false);
  const [newArticle, setNewArticle] = useState({ title: '', subject: '', content: '' });
  
  const { data: articles  } = useGetArticlesQuery();
  const [createArticle] = useCreateArticleMutation();

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
    setShowModal(true);
  };

  const handleNewArticleSubmit = async () => {
    await createArticle(newArticle);
    setShowNewArticleModal(false);
    setNewArticle({ title: '', subject: '', content: '' });
  };

  function parseContent(content) {
    const replaceImageTags = (text) => {    // Replace custom image tags with actual img elements
    return text.replace(/\[img\s+src="([^"]+)"\]\[\/img\]/g, '<img src="$1" alt="Article image" style="max-width: 100%; width: 100%;" />');
  };

    // Apply the replacement
    const parsedContent = replaceImageTags(content);

    // Return the parsed content
    return parsedContent;
  }

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['clean']
    ]
  };

  

  return (
    <div>
      <h3 className="mt-10px">News</h3>
      <button className="btn btn-primary float-right" onClick={() => setShowNewArticleModal(true)}>New Article</button>
      <div className="list-group">
        {articles && articles.map(article => (
          <div 
            key={article.id} 
            className="list-group-item list-group-item-action" 
            onClick={() => handleArticleClick(article)}
            style={{ cursor: 'pointer' }}
          >
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">{article.title}</h5>
              <small>
                Written by {article.author.username} on {new Date(article.date).toLocaleDateString()}
              </small>
            </div>
            <p className="mb-1">{article.subject}</p>
          </div>
        ))}
      </div>
      
      {showModal && selectedArticle && (
  <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header justify-content-center">
          <h4 className="modal-title text-center">{selectedArticle.title}</h4>
        </div>
        <div className="modal-body">
          <div className="text-center mb-3">
            <h6>{selectedArticle.subject}</h6>
            <hr />
          </div>
          <div 
            className="ql-editor" 
            dangerouslySetInnerHTML={{ __html: parseContent(selectedArticle.content) }} 
          />
        </div>
        <div className="modal-footer">
          <small className="mr-auto">
            Written by {selectedArticle.author.username} on {new Date(selectedArticle.date).toLocaleDateString()}
          </small>
          <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
        </div>
      </div>
    </div>
  </div>
)}


      {showNewArticleModal && (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">New Article</h5>
                <button type="button" className="close" onClick={() => setShowNewArticleModal(false)}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <input 
                  type="text" 
                  className="form-control mb-2" 
                  placeholder="Title" 
                  value={newArticle.title} 
                  onChange={(e) => setNewArticle({...newArticle, title: e.target.value})}
                />
                <input 
                  type="text" 
                  className="form-control mb-2" 
                  placeholder="Subject" 
                  value={newArticle.subject} 
                  onChange={(e) => setNewArticle({...newArticle, subject: e.target.value})}
                />
                <ReactQuill 
                  value={newArticle.content} 
                  onChange={(content) => setNewArticle({...newArticle, content})}
                  modules={modules}

                />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowNewArticleModal(false)}>Close</button>
                <button type="button" className="btn btn-primary" onClick={handleNewArticleSubmit}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function YourStats() {
  const { data: userStats, isLoading, error } = useGetUserStatsQuery();

  if (isLoading) return <p>Loading your stats...</p>;
  if (error) return <p>Error loading your stats: {error.message}</p>;

  return (
    <div>
      <h3 className="mt-10px">Your Stats</h3>
      <table className="table">
        <tbody>
          <tr><td>Email:</td><td>{userStats.email}</td></tr>
          <tr><td>Referral Link:</td><td>{userStats.referralLink}</td></tr>
          <tr><td>Signup Date:</td><td>{new Date(userStats.signupDate).toLocaleDateString()}</td></tr>
          <tr><td>Money in Bank:</td><td>£{userStats.bankBalance}</td></tr>
          <tr><td>Kills:</td><td>{userStats.kills}</td></tr>
          <tr><td>Health:</td><td>{userStats.health}%</td></tr>
        </tbody>
      </table>
    </div>
  );
}

const achievementData = [
  { id: 1, name: 'Crimes', icon: '🔪', progress: 70, total: 100 },
  { id: 2, name: 'Advanced Crimes', icon: '🦹', progress: 30, total: 50 },
  { id: 3, name: 'Car Theft', icon: '🚗', progress: 20, total: 30 },
  { id: 4, name: 'Boat Theft', icon: '🚤', progress: 5, total: 10 },
  { id: 5, name: 'Extortion', icon: '💰', progress: 15, total: 25 },
];

const Achievements = () => (  
  <div className="achievement-cards">
    {achievementData.map(achievement => (
      <div key={achievement.id} className="achievement-card">
        <div className="achievement-icon">{achievement.icon}</div>
        <h3>{achievement.name}</h3>
        <progress value={achievement.progress} max={achievement.total}></progress>
        <p>{achievement.progress} / {achievement.total}</p>
      </div>
    ))}
  </div>
);

function Dashboard() {
  const [activeTab, setActiveTab] = useState('updates');
  const [activeStatTab, setActiveStatTab] = useState('userStats');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="family-tabs-container">
    <div className="tabs-wrapper">
      <ul className="nav nav-tabs flex-nowrap">
        <li className="nav-item">
          <a href="#updates-tab" onClick={() => handleTabClick('updates')} className={`nav-link ${activeTab === 'updates' ? 'active' : ''}`}>Updates</a>
        </li>
        <li className="nav-item">
          <a href="#news-tab" onClick={() => handleTabClick('news')} className={`nav-link ${activeTab === 'news' ? 'active' : ''}`}>News</a>
        </li>
        <li className="nav-item">
          <a href="#game-stats-tab" onClick={() => handleTabClick('gameStats')} className={`nav-link ${activeTab === 'gameStats' ? 'active' : ''}`}>Game Stats</a>
        </li>
        <li className="nav-item">
          <a href="#your-character-tab" onClick={() => handleTabClick('yourCharacter')} className={`nav-link ${activeTab === 'yourCharacter' ? 'active' : ''}`}>Your Character</a>
        </li>
        <li className="nav-item">
          <a href="#activity-tab" onClick={() => handleTabClick('activity')} className={`nav-link ${activeTab === 'activity' ? 'active' : ''}`}>Activity</a>
        </li>
        <li className="nav-item">
          <a href="#achievements-tab" onClick={() => handleTabClick('achievements')} className={`nav-link ${activeTab === 'achievements' ? 'active' : ''}`}>Achievements</a>
        </li>
        <li className="nav-item">
          <a href="#your-stats-tab" onClick={() => handleTabClick('yourStats')} className={`nav-link ${activeTab === 'yourStats' ? 'active' : ''}`}>Your Stats</a>
        </li>
      </ul>
    </div>
          <div className="tab-content panel rounded-0 p-3 m-0">
            <div className={`tab-pane fade ${activeTab === 'updates' ? 'active show' : ''}`} id="updates-tab">
              {activeTab === 'updates' && <GameUpdates />}
            </div>
            <div className={`tab-pane fade ${activeTab === 'news' ? 'active show' : ''}`} id="news-tab">
              {activeTab === 'news' && <News />}
            </div>
            <div className={`tab-pane fade ${activeTab === 'gameStats' ? 'active show' : ''}`} id="game-stats-tab">
              {activeTab === 'gameStats' && <GameStats activeStatTab={activeStatTab} setActiveStatTab={setActiveStatTab} />}
            </div>
            <div className={`tab-pane fade ${activeTab === 'yourCharacter' ? 'active show' : ''}`} id="your-character-tab">
              <h3 className="mt-10px">Your Character</h3>
              <p>Content for Your Character tab goes here.</p>
            </div>
            <div className={`tab-pane fade ${activeTab === 'activity' ? 'active show' : ''}`} id="activity-tab">
              <h3 className="mt-10px">Activity</h3>
              <p>Content for Activity tab goes here.</p>
            </div>
            <div className={`tab-pane fade ${activeTab === 'achievements' ? 'active show' : ''}`} id="achievements-tab">
              <h3 className="mt-10px">Achievements</h3>
              {activeTab === 'achievements' && <Achievements />}
            </div>
            <div className={`tab-pane fade ${activeTab === 'yourStats' ? 'active show' : ''}`} id="your-stats-tab">
              {activeTab === 'yourStats' && <YourStats />}
              </div>
          </div>
        </div>
  );
}

export default Dashboard;
