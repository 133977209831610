import React, { useState } from 'react';

const Items = () => {
  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Items</h3>
        {/* Add items content here */}
      </div>
    </div>
  );
};

const Properties = () => {
  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Properties</h3>
        {/* Add properties content here */}
      </div>
    </div>
  );
};

const Insurance = () => {
  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Insurance</h3>
        {/* Add insurance content here */}
      </div>
    </div>
  );
};

const Points = () => {
  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Points</h3>
        {/* Add points content here */}
      </div>
    </div>
  );
};

const Lockpicks = () => {
  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Lockpicks</h3>
        {/* Add lockpicks content here */}
      </div>
    </div>
  );
};

const BlackMarket = () => {
  const [activeTab, setActiveTab] = useState('items');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a href="#items-tab" onClick={() => handleTabClick('items')} className={`nav-link ${activeTab === 'items' ? 'active' : ''}`}>
            <span className="d-sm-none">Items</span>
            <span className="d-sm-block d-none">Items</span>
          </a>
        </li>
        <li className="nav-item">
          <a href="#properties-tab" onClick={() => handleTabClick('properties')} className={`nav-link ${activeTab === 'properties' ? 'active' : ''}`}>
            <span className="d-sm-none">Properties</span>
            <span className="d-sm-block d-none">Properties</span>
          </a>
        </li>
        <li className="nav-item">
          <a href="#insurance-tab" onClick={() => handleTabClick('insurance')} className={`nav-link ${activeTab === 'insurance' ? 'active' : ''}`}>
            <span className="d-sm-none">Insurance</span>
            <span className="d-sm-block d-none">Insurance</span>
          </a>
        </li>
        <li className="nav-item">
          <a href="#points-tab" onClick={() => handleTabClick('points')} className={`nav-link ${activeTab === 'points' ? 'active' : ''}`}>
            <span className="d-sm-none">Points</span>
            <span className="d-sm-block d-none">Points</span>
          </a>
        </li>
        <li className="nav-item">
          <a href="#lockpicks-tab" onClick={() => handleTabClick('lockpicks')} className={`nav-link ${activeTab === 'lockpicks' ? 'active' : ''}`}>
            <span className="d-sm-none">Lockpicks</span>
            <span className="d-sm-block d-none">Lockpicks</span>
          </a>
        </li>
      </ul>
      <div className="tab-content panel rounded-0 p-3 m-0">
        <div className={`tab-pane fade ${activeTab === 'items' ? 'active show' : ''}`} id="items-tab">
          <Items />
        </div>
        <div className={`tab-pane fade ${activeTab === 'properties' ? 'active show' : ''}`} id="properties-tab">
          <Properties />
        </div>
        <div className={`tab-pane fade ${activeTab === 'insurance' ? 'active show' : ''}`} id="insurance-tab">
          <Insurance />
        </div>
        <div className={`tab-pane fade ${activeTab === 'points' ? 'active show' : ''}`} id="points-tab">
          <Points />
        </div>
        <div className={`tab-pane fade ${activeTab === 'lockpicks' ? 'active show' : ''}`} id="lockpicks-tab">
          <Lockpicks />
        </div>
      </div>
    </div>
  );
};

export default BlackMarket;
