import React, { useState } from 'react';
import { useExtortionlistQuery, useExtortMutation } from '../../api/crime';
import Response from '../../shared-components/response';
import { useGlobalState } from '../../auth/globalState';


const Extortion = () => {
  const [selectedFamily, setSelectedFamily] = useState('null');
  const { data: families, isLoading, isError } = useExtortionlistQuery();
  const [extort, { isLoading: isExtorting }] = useExtortMutation();
  const [response, setResponse] = useState({ message: '', type: '' });
  const { updateTimer } = useGlobalState();


  const handleFamilyChange = (event) => {
    setSelectedFamily(event.target.value);
  };

  const handleExtort = async () => {
    try {
      const result = await extort({ familyUuid: selectedFamily }).unwrap();
      setResponse({ message: result.message, type: result.type });
      if (result.time) {
        updateTimer('extortion', result.time);
      }
    } catch (error) {
      setResponse({ message: 'Failed to extort. Try again later.', type: 'danger' });
    }
  };

  if (isLoading) return <div>Loading families...</div>;
  if (isError) return <div>Error loading families. Please try again.</div>;

  return (
    <div className="row">
      <div className="col-lg-3"></div>
      <div className="col-lg-6">
        <div className="card">
          <img src="/assets/img/extortion.jpg" alt="" className="card-img-top" />
          <div className="card-body">
            <h2 className="card-title text-center mb-4">Extortion</h2>
            <div className="mb-4">
              <select 
                className="form-select" 
                value={selectedFamily} 
                onChange={handleFamilyChange}
              >
                {families.map((family) => (
                  <option key={family.uuid} value={family.uuid}>
                    {family.name}
                  </option>
                ))}
              </select>
            </div>
            <Response message={response.message} type={response.type} />
            <div className="d-flex justify-content-end">
              <button 
                className="btn btn-primary" 
                type="button" 
                onClick={handleExtort}
                disabled={isExtorting}
              >
                {isExtorting ? 'Extorting...' : 'Extort'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3"></div>
    </div>
  );
};

export default Extortion;
