import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useGetMessengerChatQuery, useSendMessengerMessageMutation } from '../../api/comm';
import { useGlobalState } from '../../auth/globalState';

function ChatArea({ isOpen }) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isChatActive, setIsChatActive] = useState(false);
  const { globalState } = useGlobalState();

  const { data: chatData, isLoading } = useGetMessengerChatQuery(undefined, {
    skip: !globalState.character_uuid || !isChatActive,
  });
  const [sendMessage] = useSendMessengerMessageMutation();

  useEffect(() => {
    if (chatData) {
      setMessages([...chatData].reverse());
    }
  }, [chatData]);
  

  useEffect(() => {
    if (isOpen) {
      setIsChatActive(true);
    }
  }, [isOpen]);

  const handleSend = async () => {
    if (newMessage.trim() && globalState.character_uuid) {
      try {
        await sendMessage({ message: newMessage });
        setNewMessage('');
      } catch (error) {
        console.error('Failed to send message:', error);
      }
    }
  };

  if (!globalState.character_uuid) {
  }

  if (isLoading) {
    return <div>Loading chat...</div>;
  }

  return (
    <div className={`chat-area ${isOpen ? 'open' : ''}`} data-bs-theme="dark">
      <div className="chat-header">Game Chat</div>
      <PerfectScrollbar className="chat-messages" options={{suppressScrollX: true}}>
        {messages.map((msg) => (
          <div key={msg.uuid} className="chat-bubble">
            <div className="chat-username">{msg.Users_character.username}</div>
            <div className="chat-message">{msg.content}</div>
            <div className="chat-time">
              {new Date(msg.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            </div>
          </div>
        ))}
      </PerfectScrollbar>
      <div className="chat-input">
        <input 
          type="text" 
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message..."
        />
        <button onClick={handleSend}>Send</button>
      </div>
    </div>
  );
}

export default ChatArea;
