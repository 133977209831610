import React, { useEffect } from 'react';
import { useGetNotificationsQuery } from '../../../api/general';
import { useGlobalState } from '../../../auth/globalState';




function DropdownNotification() {

	const { globalState } = useGlobalState();
	const { data: notifications, isLoading, isError, refetch } = useGetNotificationsQuery(undefined, {
	  skip: !globalState.character_uuid
	});

	useEffect(() => {
		if (globalState.character_uuid) {
		  const interval = setInterval(() => {
			refetch();
		  }, 10000); // 10 seconds
	
		  return () => clearInterval(interval);
		}
	  }, [refetch, globalState.character_uuid]);



  const unreadCount = notifications ? notifications.filter(n => !n.read).length : 0;

  if (!globalState.character_uuid) {
	return null; // Don't render anything if character_uuid is null
  }

  return (
    <div className="navbar-item dropdown">
      <a href="#/" data-bs-toggle="dropdown" className="navbar-link dropdown-toggle icon">
        <i className="fa fa-bell fa-2x" style={{ fontSize: '2em' }}></i>
        {unreadCount > 0 && <span className="badge">{unreadCount}</span>}
      </a>
      <div className="dropdown-menu media-list dropdown-menu-end">
        <div className="dropdown-header">NOTIFICATIONS ({notifications ? notifications.length : 0})</div>
        {notifications && notifications.length > 0 ? (
          notifications.map(notification => (
            <a href={notification.link} className="dropdown-item media" key={notification.uuid}>
              <div className="media-left">
                <i className={`fa ${notification.icon} media-object bg-gray-500`}></i>
              </div>
              <div className="media-body">
                <h6 className="media-heading">{notification.title}</h6>
                <p>{notification.description}</p>
                <div className="text-muted fs-10px">{new Date(notification.time).toLocaleString()}</div>
              </div>
            </a>
          ))
        ) : (
          <div className="dropdown-item">You have no notifications!</div>
        )}
        <div className="dropdown-footer text-center">
          <a href="#/" className="text-decoration-none">View notification history</a>
        </div>
      </div>
    </div>
  );
}

export default DropdownNotification;
