import React, { useState } from 'react';
import { usePlayScratchcardMutation, useGetLastScratchcardsQuery } from '../../api/properties';
import Response from '../../shared-components/response';

const Scratchcards = () => {
  const [playScratchcard, { data: scratchcardResult, isLoading}] = usePlayScratchcardMutation();
  const { data: lastScratchcards, refetch: refetchLastScratchcards } = useGetLastScratchcardsQuery();
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  const handlePlay = async (tier) => {
    try {
      const result = await playScratchcard({ tier }).unwrap();
      setAlertData({ message: result.message, type: result.type });
      refetchLastScratchcards();
    } catch (error) {
      console.error('Error playing scratchcard:', error);
      setAlertData({ message: 'Error playing scratchcard', type: 'danger' });
    }
  };

  const scratchcardTiers = [
    { name: 'Bronze', cost: 1000000, color: '#CD7F32' },
    { name: 'Silver', cost: 5000000, color: '#C0C0C0' },
    { name: 'Gold', cost: 10000000, color: '#FFD700' }
  ];

  return (
    <div>
      <Response 
        message={alertData.message} 
        type={alertData.type}
        scratchcardResult={scratchcardResult?.reward}
      />

      <div className="tab-content rounded-0 m-0">
        <div className="row">
          {scratchcardTiers.map((tier) => (
            <div className="col-lg-4" key={tier.name}>
              <div className="card mb-3" style={{borderColor: tier.color}}>
                <img src="https://placehold.co/600x400" className="card-img-top" alt={`${tier.name} Scratchcard`} />
                <div className="card-body">
                  <h3 className="card-title text-center">{tier.name} Scratchcard</h3>
                  <div className="d-flex flex-column align-items-center">
                    <button 
                      onClick={() => handlePlay(tier.name.toLowerCase())} 
                      className="btn btn-primary mb-2"
                      style={{backgroundColor: tier.color, borderColor: tier.color}}
                    >
                      Buy and Scratch
                    </button>
                    <p className="mb-0">Price: £{tier.cost.toLocaleString()}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="card mt-3">
              <div className="card-body">
                <h4>Last 10 Scratchcards</h4>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Reward</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lastScratchcards && lastScratchcards.map((scratchcard, index) => (
                      <tr key={index}>
                        <td>{scratchcard.type}</td>
                        <td>{scratchcard.reward}</td>
                        <td>{new Date(scratchcard.date).toLocaleString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scratchcards;
