
function addGamePrefix(menuItems) {
  return menuItems.map(item => {
    const newItem = { ...item };
    if (newItem.path && !newItem.path.startsWith('/game')) {
      newItem.path = `/game${newItem.path}`;
    }
    if (newItem.children) {
      newItem.children = addGamePrefix(newItem.children);
    }
    return newItem;
  });
}

const Menu = addGamePrefix([
  {
    path: '/general', icon: 'fa fa-sitemap', title: 'General',
    children: [
      { path: '/general/dashboard', title: 'Dashboard - unfinished' },
      { path: '/general/travel', title: 'Travel' },
      { path: '/general/finance', title: 'Your Money' },
      { path: '/general/player-directory', title: 'Player Directory - unfinished' },
      { path: '/general/properties', title: 'Properties' },
      { path: '/family/family', title: 'Family - unfinished' },
      { path: '/general/points', title: 'Points -NA' },
    ]
  },
  {
    path: '/messaging', icon: 'fa fa-envelope', title: 'Messaging',
    children: [
      { path: '/messaging/inbox', title: 'Inbox' },
      { path: '/messaging/idea-centre', title: 'Idea Centre' },
      { path: '/messaging/main-forum?forum=main', title: 'Main Forum' },
      { path: '/messaging/main-forum?forum=family', title: 'Family Forum' },
    ]
  },
  {
    path: '/crime', icon: 'fa fa-mask', title: 'Crime',
    children: [
      { path: '/crime/missions', title: 'Missions -NA' },
      { path: '/crime/organised-crime', title: 'Organised Crime -NA' },
      { path: '/crime/drugs', title: 'Drugs -NA' },
      { path: '/crime/smuggling', title: 'Smuggling -NA' },
      { path: '/crime/mug', title: 'Mug -NA' },
      { path: '/crime/prison', title: 'Prison -NA' },
      { path: '/crime/encounters', title: 'Encounters -NA' },
      { path: '/crime/syndicates', title: 'Syndicates -NA' },
    ]
  },
  {
    path: '/kill', icon: 'fa fa-skull', title: 'Kill',
    children: [
      { path: '/kill/kill', title: 'Kill - unfinished' },
      { path: '/kill/bullet-factory', title: 'Bullet Factory', label2: 'inprogress' },
      { path: '/kill/kevlar-factory', title: 'Kevlar Factory' },
      { path: '/kill/foundry', title: 'Foundry - NA' },
      { path: '/kill/russian-roulette', title: 'Russian Roulette - NA' },
    ]
  },
  {
    path: '/marketplace', icon: 'fa fa-store', title: 'Marketplace',
    children: [
      { path: '/marketplace/refinery', title: 'Refinery - NA' },
      { path: '/marketplace/bar', title: 'Bar' },
      { path: '/marketplace/black-market', title: 'Black Market - unfinished' },
      { path: '/marketplace/vehicles', title: 'Vehicles - unfinished' },
      { path: '/marketplace/life-insurance', title: 'Life Insurance - NA' },
    ]
  },
  {
    path: '/casino', icon: 'fa fa-dice', title: 'Casino',
    children: [
      { path: '/casino/roulette', title: 'Roulette' },
      { path: '/casino/dice', title: 'Dice - NA' },
      { path: '/casino/slots', title: 'Slots' },
      { path: '/casino/heads-or-tails', title: 'Heads or Tails - NA' },
      { path: '/casino/boxing', title: 'Boxing - in progress' },
      { path: '/casino/lottery', title: 'Lottery - NA' },
      { path: '/casino/keno', title: 'Keno - NA' },
      { path: '/casino/scratchcards', title: 'Scratchcards' },
    ]
  },
]);
export default Menu;