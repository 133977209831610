import { apiSlice } from '../auth/apiSlice';

export const crimeApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // Advanced Crimes
        listAdvancedCrimes: builder.query({
            query: () => '/crimes/listadvancedcrimes',
            providesTags: ['advcrimes_page'],
            refetchOnMountOrArgChange: true
        }),
        commitAdvancedCrime: builder.mutation({
            query: body => ({
                url: '/crimes/commitadvancedcrime',
                method: 'POST',
                body: { ...body },
            }),
        }),

        // Boat Theft
        listBoatTheftOptions: builder.query({
            query: () => '/crimes/listboattheftoptions',
            providesTags: ['bta_page'],
            refetchOnMountOrArgChange: true
        }),
        commitBoatTheft: builder.mutation({
            query: body => ({
                url: '/crimes/commitbta',
                method: 'POST',
                body: { ...body },
            }),
        }),
 
        // Car Theft (GTA)
        listCarTheftOptions: builder.query({
            query: () => '/crimes/listcartheftoptions',
            providesTags: ['gta_page'],
            refetchOnMountOrArgChange: true
        }),
        commitCarTheft: builder.mutation({
            query: body => ({
                url: '/crimes/commitgta',
                method: 'POST',
                body: { ...body },
            }),
        }),

        // Regular Crimes
        listCrimes: builder.query({
            query: () => '/crimes/listcrimes',
            providesTags: ['crimes_page'],
            refetchOnMountOrArgChange: true
        }),
        commitCrime: builder.mutation({
            query: body => ({
                url: '/crimes/commitcrime',
                method: 'POST',
                body: { ...body },
            }),
        }),

        // Chase
        commitChase: builder.mutation({
            query: body => ({
                url: '/crimes/chase',
                method: 'POST',
                body: { ...body },
            }),
        }),
        extortionlist: builder.query({
            query: () => '/crimes/extortionlist',
            providesTags: ['families'],
            refetchOnMountOrArgChange: true
        }),
        extort: builder.mutation({
            query: body => ({
                url: '/crimes/extort',
                method: 'POST',
                body: { ...body },
            }),
        }),
















    }),
});

export const {
    useListAdvancedCrimesQuery,
    useCommitAdvancedCrimeMutation,
    useListBoatTheftOptionsQuery,
    useCommitBoatTheftMutation,
    useListCarTheftOptionsQuery,
    useCommitCarTheftMutation,
    useListCrimesQuery,
    useCommitCrimeMutation,
    useCommitChaseMutation,
    useExtortionlistQuery,
    useExtortMutation,
} = crimeApi;
