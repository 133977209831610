import React, { useState } from 'react';
import { useGetAllPropertiesQuery, useCollectPropertyMutation } from '../../api/properties';

const Properties = () => {
  const { data: properties, isLoading, isError, refetch } = useGetAllPropertiesQuery();
  const [collectProperty] = useCollectPropertyMutation();
  const [activeLocation, setActiveLocation] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading properties</div>;

  const handleCollect = async (locationId, propertyType) => {
    setSelectedProperty({ locationId, propertyType });
    setShowModal(true);
  };
  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  
  const confirmCollect = async () => {
    try {
      await collectProperty(selectedProperty).unwrap();
      setShowModal(false);
      refetch();
    } catch (error) {
      console.error('Failed to collect property:', error);
    }
  };

  const getCost = (propertyType) => {
    return propertyType.includes('Factory') ? '150 million' : '25 million';
  };

  return (
    <div>
      <div className="row">
      <div className="family-tabs-container">
  <div className="tabs-wrapper">
    <ul className="nav nav-tabs flex-nowrap">
      {properties.map((location) => (
        <li className="nav-item" key={location.uuid}>
          <a 
            href={`#${location.city}`} 
            onClick={() => setActiveLocation(location.uuid)} 
            className={`nav-link ${activeLocation === location.uuid ? 'active' : ''}`}
          >
            {location.city}
          </a>
        </li>
      ))}
    </ul>
  </div>

          <div className="tab-content panel rounded-0 p-3 m-0">
            {properties.map((location) => (
              <div 
                key={location.uuid} 
                className={`tab-pane fade ${activeLocation === location.uuid ? 'active show' : ''}`} 
                id={location.city}
              >
                <h3 className="mt-10px">{location.city} Properties</h3>
                    <table className="table">
                    <thead>
                        <tr>
                        <th>Property Type</th>
                        <th>Owner</th>
                        <th>Max Bet / Price</th>
                        <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {['roulette', 'slots', 'bulletFactory', 'kevlarFactory'].map((propertyType) => (
                        <tr key={propertyType}>
                            <td>{capitalizeWords(propertyType.replace(/([A-Z])/g, ' $1').trim())}</td>
                            <td>{location[`${propertyType}Owner`] || 'No owner'}</td>
                            <td>
                            {propertyType.includes('Factory') 
                                ? `£${location[`${propertyType}Price`] || 'N/A'}`
                                : `£${location[`${propertyType}MaxBet`] || 'N/A'}`
                            }
                            </td>
                            <td>
                            {!location[`${propertyType}Owner`] && (
                                <button 
                                className="btn btn-primary btn-sm"
                                onClick={() => handleCollect(location.uuid, propertyType)}
                                >
                                Collect Property
                                </button>
                            )}
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
              </div>
            ))}
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Property Collection</h5>
                <button type="button" className="close" onClick={() => setShowModal(false)}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>This will cost {getCost(selectedProperty.propertyType)}. Do you want to proceed?</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={confirmCollect}>Confirm</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Properties;
