// 2024 finances.js

import React, { useState, useEffect } from 'react';
import { useGetBankBalanceQuery, useBankTransactionMutation, useBankTransferMutation, useCreateSavingsAccountMutation, useCheckSavingsAccountQuery, useCheckATMAccountQuery, useOpenATMAccountMutation, useVerifyPINMutation, usePerformATMActionMutation, useGetATMDataQuery } from '../../api/general';
import Response from '../../shared-components/response';

// Bank Tab Component
const BankTab = ({ bankBalance, balanceLoading, refetchBankBalance, setAlertData }) => {
  const [amount, setAmount] = useState('');
  const [transactionType, setTransactionType] = useState('deposit');
  const [transferUsername, setTransferUsername] = useState('');
  const [transferAmount, setTransferAmount] = useState('');

  const [bankTransaction] = useBankTransactionMutation();
  const [bankTransfer] = useBankTransferMutation();

  const handleTransaction = async () => {
    try {
      const response = await bankTransaction({ type: transactionType, amount: parseFloat(amount) }).unwrap();
      setAlertData({ message: response.message, type: response.type });
      setAmount('');
      refetchBankBalance();
    } catch (error) {
      setAlertData({ message: 'An error occurred during the transaction', type: 'error' });
      console.error('Transaction failed:', error);
    }
  };
  
  const handleTransfer = async () => {
    try {
      const response = await bankTransfer({ username: transferUsername, amount: parseFloat(transferAmount) }).unwrap();
      setAlertData({ message: response.message, type: response.type });
      setTransferUsername('');
      setTransferAmount('');
      refetchBankBalance();
    } catch (error) {
      setAlertData({ message: 'An error occurred during the transfer', type: 'error' });
      console.error('Transfer failed:', error);
    }
  };

  return (
    <div className="row">
      <div className="col-md-6 mb-3">
        <div className="card">
          <div className="card-body">
          <h3 className="card-title">Balance</h3>
            {balanceLoading ? (
              <p>Loading balance...</p>
            ) : (
              <p className="card-text">
                Current Balance: £{bankBalance?.balance}
                {bankBalance?.interestTime > 0 && (
                  <span className="text-success"> (Interest will pay out in {new Date(bankBalance.interestTime).toISOString().substr(11, 8)})</span>
                )}
              </p>
            )}
            <input 
              type="number" 
              className="form-control mb-2"
              value={amount} 
              onChange={(e) => setAmount(e.target.value)} 
              placeholder="Amount"
            />
            <div className="mb-2">
              <div className="form-check form-check-inline">
                <input 
                  className="form-check-input"
                  type="radio" 
                  id="deposit"
                  value="deposit" 
                  checked={transactionType === 'deposit'} 
                  onChange={() => setTransactionType('deposit')} 
                />
                <label className="form-check-label" htmlFor="deposit">Deposit</label>
              </div>
              <div className="form-check form-check-inline">
                <input 
                  className="form-check-input"
                  type="radio" 
                  id="withdraw"
                  value="withdraw" 
                  checked={transactionType === 'withdraw'} 
                  onChange={() => setTransactionType('withdraw')} 
                />
                <label className="form-check-label" htmlFor="withdraw">Withdraw</label>
              </div>
            </div>
            <button className="btn btn-primary" onClick={handleTransaction}>Submit</button>
          </div>
        </div>
      </div>

      <div className="col-md-6 mb-3">
        <div className="card">
          <div className="card-body">
            <h3 className="card-title">Transfers</h3>
            <input 
              type="text" 
              className="form-control mb-2"
              value={transferUsername} 
              onChange={(e) => setTransferUsername(e.target.value)} 
              placeholder="Username"
            />
            <input 
              type="number" 
              className="form-control mb-2"
              value={transferAmount} 
              onChange={(e) => setTransferAmount(e.target.value)} 
              placeholder="Amount"
            />
            <button className="btn btn-primary" onClick={handleTransfer}>Transfer</button>
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <h3 className="card-title">Recent Transfers</h3>
            {balanceLoading ? (
              <p>Loading transactions...</p>
            ) : bankBalance?.transactions && bankBalance.transactions.length > 0 ? (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Transaction Details</th>
                  </tr>
                </thead>
                <tbody>
                  {bankBalance.transactions.map(transfer => (
                    <tr key={transfer.uuid}>
                      <td>
                        {transfer.sender.username === bankBalance.username 
                          ? `You sent £${transfer.amount} to ${transfer.recipient.username}`
                          : `You received £${transfer.amount} from ${transfer.sender.username}`
                        }
                        <br />
                        <small className="text-muted">{new Date(transfer.date).toLocaleString()}</small>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="alert alert-info">
                You haven't sent or received any transactions yet.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ATMTab = ({ resetATM, setAlertData }) => {
  const [hasATM, setHasATM] = useState(false);
  const [showATMInterface, setShowATMInterface] = useState(false);
  const [pin, setPin] = useState('');
  const [amount, setAmount] = useState('');
  const [action, setAction] = useState('deposit');
  const [showOpenATMModal, setShowOpenATMModal] = useState(false);
  const [newPin, setNewPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');
  const [atmBalance, setATMBalance] = useState(0);  
  const { data: atmStatus, refetch: refetchATMStatus } = useCheckATMAccountQuery();
  const [openATMAccount] = useOpenATMAccountMutation();
  const [verifyPIN] = useVerifyPINMutation();
  const [performATMAction] = usePerformATMActionMutation();
  const { refetch: refetchATMData } = useGetATMDataQuery();

  useEffect(() => {
    setShowATMInterface(false);
    setPin('');
    setAmount('');
    setAction('deposit');
    setAlertData({ message: '', type: '' });
    setATMBalance(0);
  }, [resetATM, setAlertData]);

  useEffect(() => {
    if (atmStatus) {
      setHasATM(atmStatus.hasATM);
    }
  }, [atmStatus]);

  const handleOpenATM = async () => {
    try {
      const response = await openATMAccount({ pin: newPin, confirmPin: confirmPin }).unwrap();
      setAlertData({ message: response.message, type: response.type });
      if (response.type === 'success') {
        refetchATMStatus();
        setShowOpenATMModal(false);
      }
    } catch (error) {
      setAlertData({ message: 'Failed to open ATM account', type: 'error' });
    }
  };

  const handlePINSubmit = async () => {
    try {
      const response = await verifyPIN({ pin }).unwrap();
      if (response.verified) {
        const atmData = await refetchATMData().unwrap();
        if (atmData.balance !== undefined) {
          setShowATMInterface(true);
          setATMBalance(atmData.balance);
          setAlertData({ message: '', type: '' });
        } else {
          setAlertData({ message: atmData.message, type: 'error' });
        }
      } else {
        setAlertData({ message: 'Invalid PIN or no ATM account open', type: 'error' });
      }
    } catch (error) {
      console.error('PIN verification error:', error);
      setAlertData({ message: 'PIN verification failed', type: 'error' });
    }
  };

  const handleATMAction = async () => {
    try {
      const response = await performATMAction({ action, amount: parseFloat(amount) }).unwrap();
      setAlertData({ message: response.message, type: response.type });
      if (response.type === 'success') {
        setAmount('');
        setATMBalance(response.balance);
      }
    } catch (error) {
      setAlertData({ message: 'ATM action failed', type: 'error' });
    }
  };

  if (!hasATM) {
    return (
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">ATM</h3>
          <p>You don't have an ATM account yet. It costs £150,000,000 to open one.</p>
          <button className="btn btn-primary" onClick={() => setShowOpenATMModal(true)}>Open an ATM Account</button>
          
          {showOpenATMModal && (
            <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Open ATM Account</h5>
                    <button type="button" className="close" onClick={() => setShowOpenATMModal(false)}>
                      <span>&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <input
                      type="password"
                      className="form-control mb-2"
                      placeholder="Create PIN"
                      value={newPin}
                      onChange={(e) => setNewPin(e.target.value)}
                    />
                    <input
                      type="password"
                      className="form-control mb-2"
                      placeholder="Confirm PIN"
                      value={confirmPin}
                      onChange={(e) => setConfirmPin(e.target.value)}
                    />
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => setShowOpenATMModal(false)}>Close</button>
                    <button type="button" className="btn btn-primary" onClick={handleOpenATM}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">ATM</h3>
        
        {!showATMInterface ? (
          <>
            <input
              type="password"
              className="form-control mb-2"
              placeholder="Enter PIN"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
            />
            <button className="btn btn-primary" onClick={handlePINSubmit}>Submit</button>
          </>
        ) : (
          <>
            <p>Balance: £{atmBalance}</p>
            <input
              type="number"
              className="form-control mb-2"
              placeholder="Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <div className="mb-2">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="deposit"
                  value="deposit"
                  checked={action === 'deposit'}
                  onChange={() => setAction('deposit')}
                />
                <label className="form-check-label" htmlFor="deposit">Deposit</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="withdraw"
                  value="withdraw"
                  checked={action === 'withdraw'}
                  onChange={() => setAction('withdraw')}
                />
                <label className="form-check-label" htmlFor="withdraw">Withdraw</label>
              </div>
            </div>
            <button className="btn btn-primary" onClick={handleATMAction}>Submit</button>
          </>
        )}
      </div>
    </div>
  );
};

const SavingsAccountsTab = ({ savingsData, checkSavingsAccount, setAlertData }) => {
  const [amount, setAmount] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [createSavingsAccount] = useCreateSavingsAccountMutation();
  
  useEffect(() => {
    if (savingsData?.message) {
      setAlertData({ message: savingsData.message, type: savingsData.type });
    }
  }, [savingsData, setAlertData]);

  const maximumDeposit = savingsData?.maxSavings || 0;

  const savingsOptions = [
    { value: '1', label: '20% for 1 day' },
    { value: '2', label: '45% for 2 days' },
    { value: '3', label: '70% for 3 days' },
    { value: '4', label: '100% for 4 days' },
  ];

  const handleSubmit = async () => {
    try {
      const response = await createSavingsAccount({ amount: parseFloat(amount), type: selectedOption }).unwrap();
      setAlertData({ message: response.message, type: response.type });
      setAmount('');
      setSelectedOption('');
      checkSavingsAccount();
    } catch (error) {
      setAlertData({ message: 'An error occurred', type: 'error' });
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Savings Accounts</h3>
        <p className="text-muted">Maximum savings amount is £{maximumDeposit}</p>
        
        <form>
          <div className="mb-3">
            <input
              type="number"
              className="form-control"
              placeholder="Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              max={maximumDeposit}
            />
          </div>
          <div className="mb-3">
            <select
              className="form-select"
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
            >
              <option value="">Select a savings option</option>
              {savingsOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <button type="button" className="btn btn-primary" onClick={handleSubmit}>
            Submit
          </button>
        </form>

        <div className="mt-4">
          <h4>Your Savings Accounts</h4>
          {savingsData?.savingsAccounts && savingsData.savingsAccounts.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>Amount</th>
                  <th>Interest Rate</th>
                  <th>Payout Time</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {savingsData.savingsAccounts.map((account, index) => (
                  <tr key={index}>
                    <td>£{account.amount}</td>
                    <td>{account.interestRate * 100}%</td>
                    <td>{new Date(account.payoutTime).toLocaleString()}</td>
                    <td>{account.paid ? 'Paid' : 'Active'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>You don't have any savings accounts yet.</p>
          )}
        </div>
      </div>
    </div>
  );
};

const Finances = () => {
  const [activeTab, setActiveTab] = useState('bank');
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const { data: bankBalance, isLoading: balanceLoading, refetch: refetchBankBalance } = useGetBankBalanceQuery();
  const { data: savingsData, refetch: checkSavingsAccount } = useCheckSavingsAccountQuery();
  const { refetch: refetchATMStatus } = useCheckATMAccountQuery();
  const [atmResetTrigger, setAtmResetTrigger] = useState(0);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    if (tabName === 'bank') {
      refetchBankBalance();
    } else if (tabName === 'savings') {
      checkSavingsAccount();
    } else if (tabName === 'atm') {
      refetchATMStatus();
      setAtmResetTrigger(prev => prev + 1);
    }
    setAlertData({ message: '', type: '' });
  };

  return (
    <div>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a href="#bank-tab" onClick={() => handleTabClick('bank')} className={`nav-link ${activeTab === 'bank' ? 'active' : ''}`}>
            <span className="d-sm-none">Bank</span>
            <span className="d-sm-block d-none">Bank</span>
          </a>
        </li>
        <li className="nav-item">
          <a href="#atm-tab" onClick={() => handleTabClick('atm')} className={`nav-link ${activeTab === 'atm' ? 'active' : ''}`}>
            <span className="d-sm-none">ATM</span>
            <span className="d-sm-block d-none">ATM</span>
          </a>
        </li>
        <li className="nav-item">
          <a href="#savings-tab" onClick={() => handleTabClick('savings')} className={`nav-link ${activeTab === 'savings' ? 'active' : ''}`}>
            <span className="d-sm-none">Savings Accounts</span>
            <span className="d-sm-block d-none">Savings Accounts</span>
          </a>
        </li>
      </ul>
      <div className="tab-content panel rounded-0 p-3 m-0">
      <Response message={alertData.message} type={alertData.type} />

        <div className={`tab-pane fade ${activeTab === 'bank' ? 'active show' : ''}`} id="bank-tab">
          <BankTab bankBalance={bankBalance} balanceLoading={balanceLoading} refetchBankBalance={refetchBankBalance} setAlertData={setAlertData} />
        </div>
        <div className={`tab-pane fade ${activeTab === 'atm' ? 'active show' : ''}`} id="atm-tab">
          <ATMTab resetATM={atmResetTrigger} setAlertData={setAlertData} />
        </div>
        <div className={`tab-pane fade ${activeTab === 'savings' ? 'active show' : ''}`} id="savings-tab">
          <SavingsAccountsTab savingsData={savingsData} checkSavingsAccount={checkSavingsAccount} setAlertData={setAlertData} />
        </div>
      </div>
    </div>
  );
};

export default Finances;
