import React from 'react';
import { Outlet } from 'react-router-dom';

import App from '../app.jsx';
import RequireAuth from '../auth/RequireAuth.js';
import { useGlobalState } from '../auth/globalState';
import Select from '../pages/auth/select';

//game routes
import Login from '../pages/auth/login.js';
import Register from '../pages/auth/register.js';
import ExtraError from '../pages/extra-error.js';

import Userprofile from '../pages/general/userProfile.js';




import Dashboard from '../pages/general/dashboard.js';
import Travel from '../pages/general/travel.js';
import Finances from '../pages/general/finances.js';
import Directory from '../pages/general/directory.js';
import Vehicles from '../pages/marketplace/vehicles.js';
import BlackMarket from '../pages/marketplace/blackMarket.js';

import BoatTheft from '../pages/crime/boat_theft.jsx';
import AdvancedCrimes from '../pages/crime/advcrimes.jsx';
import Chase from '../pages/crime/chase.jsx';
import Crimes from '../pages/crime/crimes.jsx';
import Extortion from '../pages/crime/extortion.jsx';
import CarTheftPage from '../pages/crime/gta.jsx';


import IdeaCentre from '../pages/messaging/ideaCentre.js';
import Forums from '../pages/messaging/forums.js';
import Inbox from '../pages/messaging/inbox.js';

import EditProfilePage from '../pages/misc/edit-profile.js';

import BarPage from '../pages/marketplace/bar.js';

import Properties from '../pages/properties/properties.js';
import Roulette from '../pages/properties/roulette.js';
import Slots from '../pages/properties/slots.js';
import Boxing from '../pages/general/boxing.js';
import Scratchcard from '../pages/properties/scratchcards.js';

import Kill from '../pages/kill/kill.js';

import BulletFactory from '../pages/properties/bulletFactory.js';
import KevlarFactory from '../pages/properties/kevlarFactory.js';

import Family from '../pages/family/family.js';


const CharacterCheck = ({ children }) => {
  const { globalState } = useGlobalState();
  
  if (globalState.character_uuid === null) {
    return <Select />;
  }
  
  return children;
};

const AppRoute = [
  {
    path: '*', 
    element: <App />,
    children: [
      { path: '', element: <Login /> },
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      {
        path: 'game',
        element: <RequireAuth />,
        children: [
          {
            path: '',
            element: <CharacterCheck>
              <Outlet />
            </CharacterCheck>,
            children: [
              { 
                path: 'general/*',
                element: <Outlet />,
                children: [
                  { path: 'dashboard', element: <Dashboard /> },
                  { path: 'travel', element: <Travel /> },
                  { path: 'finance', element: <Finances /> },
                  { path: 'player-directory', element: <Directory /> },
                  { path: 'properties', element: <Properties /> },
                  { path: 'userprofile/:username', element: <Userprofile /> },
                ]
              },
              {
                path: 'crime/*',
                element: <Outlet />,
                children: [
                  { path: 'bta', element: <BoatTheft /> },
                  { path: 'adv-crime', element: <AdvancedCrimes /> },
                  { path: 'chase', element: <Chase /> },
                  { path: 'crimes', element: <Crimes /> },
                  { path: 'extortion', element: <Extortion /> },
                  { path: 'gta', element: <CarTheftPage /> },
                  { path: '*', element: <ExtraError /> }
                ]
              },
              {
                path: 'messaging/*',
                element: <Outlet />,
                children: [
                  { path: 'idea-centre', element: <IdeaCentre /> },                
                  { path: 'main-forum', element: <Forums /> },
                  { path: 'inbox', element: <Inbox /> },
                ],
              },
              {
              path: 'marketplace/*',
              element: <Outlet />,
              children: [
                { path: 'black-market', element: <BlackMarket /> },
                { path: 'vehicles', element: <Vehicles /> },
                { path: 'bar', element: <BarPage /> },
              ]
              },
              {
                path: 'misc/*',
                element: <Outlet />,
                children: [
                  { path: 'profile-settings', element: <EditProfilePage /> }
                ]
              },
              {
                path: 'kill/*',
                element: <Outlet />,
                children: [
                  { path: 'kill', element: <Kill /> },
                  { path: 'bullet-factory', element: <BulletFactory /> },
                  { path: 'kevlar-factory', element: <KevlarFactory /> },
                ]
              },
              {
                path: 'family/*',
                element: <Outlet />,
                children: [
                  { path: 'family', element: <Family /> },
                ]
              },
              {
                path: 'casino/*',
                element: <Outlet />,
                children: [
                  { path: 'roulette', element: <Roulette /> },
                  { path: 'slots', element: <Slots /> },
                  { path: 'boxing', element: <Boxing /> },
                  { path: 'scratchcards', element: <Scratchcard /> }

                ]
              },
              
            ]
          },
          { path: 'select', element: <Select /> },
          { path: '*', element: <ExtraError /> }
        ]
      },
      { path: '*', element: <ExtraError /> }
    ]
  }
];

export default AppRoute;
