import React, { useState } from 'react';

function KillTab() {
  // Component for the Kill tab
  return <h3>Kill content goes here</h3>;
}

function Attempts() {
  // Component for the Attempts tab
  return <h3>Attempts content goes here</h3>;
}

function Statements() {
  // Component for the Statements tab
  return <h3>Statements content goes here</h3>;
}

function Hitlist() {
  // Component for the Hitlist tab
  return <h3>Hitlist content goes here</h3>;
}

function Kill() {
  const [activeTab, setActiveTab] = useState('kill');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div>
      <div className="row">
        <div className="col-xl-12">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a href="#kill-tab" onClick={() => handleTabClick('kill')} className={`nav-link ${activeTab === 'kill' ? 'active' : ''}`}>
                <span className="d-sm-none">Kill</span>
                <span className="d-sm-block d-none">Kill</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="#attempts-tab" onClick={() => handleTabClick('attempts')} className={`nav-link ${activeTab === 'attempts' ? 'active' : ''}`}>
                <span className="d-sm-none">Attempts</span>
                <span className="d-sm-block d-none">Attempts</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="#statements-tab" onClick={() => handleTabClick('statements')} className={`nav-link ${activeTab === 'statements' ? 'active' : ''}`}>
                <span className="d-sm-none">Statements</span>
                <span className="d-sm-block d-none">Statements</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="#hitlist-tab" onClick={() => handleTabClick('hitlist')} className={`nav-link ${activeTab === 'hitlist' ? 'active' : ''}`}>
                <span className="d-sm-none">Hitlist</span>
                <span className="d-sm-block d-none">Hitlist</span>
              </a>
            </li>
          </ul>
          <div className="tab-content panel rounded-0 p-3 m-0">
            <div className={`tab-pane fade ${activeTab === 'kill' ? 'active show' : ''}`} id="kill-tab">
              {activeTab === 'kill' && <KillTab />}
            </div>
            <div className={`tab-pane fade ${activeTab === 'attempts' ? 'active show' : ''}`} id="attempts-tab">
              {activeTab === 'attempts' && <Attempts />}
            </div>
            <div className={`tab-pane fade ${activeTab === 'statements' ? 'active show' : ''}`} id="statements-tab">
              {activeTab === 'statements' && <Statements />}
            </div>
            <div className={`tab-pane fade ${activeTab === 'hitlist' ? 'active show' : ''}`} id="hitlist-tab">
              {activeTab === 'hitlist' && <Hitlist />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Kill;
