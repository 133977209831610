import React, { useState } from 'react';
import { useGetFamilyInfoQuery, 
    useCreateFamilyMutation, 
    useGetFamilyInvitesQuery, 
    useInviteUserMutation, 
    useRespondToInviteMutation, 
    useKickUserMutation, 
    useLeaveFamilyMutation,  
    useGetFamilyBusinessesQuery,
    useBuyFamilyBusinessMutation,
    useSellFamilyBusinessMutation,
    useGetFollowersQuery,
    useRecruitFollowerMutation
} from '../../api/family';

import Response from '../../shared-components/response';

const CreateFamilyTab = ({ familyName, setFamilyName, handleCreateFamily }) => (
  <div className="card mb-3">
    <div className="card-body">
      <h3 className="card-title">Create a Family</h3>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Enter family name"
          value={familyName}
          onChange={(e) => setFamilyName(e.target.value)}
        />
        <button className="btn btn-primary" onClick={handleCreateFamily}>Create Family</button>
      </div>
    </div>
  </div>
);

const PendingInvitesTab = ({ invites }) => {
    const [respondToInvite] = useRespondToInviteMutation();
    const [responseMessage, setResponseMessage] = useState({ message: '', type: '' });
  
    const handleInviteResponse = async (inviteId, accept) => {
      try {
        const response = await respondToInvite({ inviteId, accept }).unwrap();
        setResponseMessage({ message: response.message, type: response.type });
      } catch (error) {
        setResponseMessage({ message: 'Failed to respond to invite', type: 'error' });
      }
    };
  
    return (
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">Pending Invites</h3>
          <Response message={responseMessage.message} type={responseMessage.type} />
          {invites && invites.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>Family Name</th>
                  <th>Invited By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {invites.map((invite) => (
                  <tr key={invite.id}>
                    <td>{invite.familyName}</td>
                    <td>{invite.invitedBy}</td>
                    <td>
                      <button className="btn btn-sm btn-success me-2" onClick={() => handleInviteResponse(invite.id, true)}>Accept</button>
                      <button className="btn btn-sm btn-danger" onClick={() => handleInviteResponse(invite.id, false)}>Decline</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No pending invites.</p>
          )}
        </div>
      </div>
    );
};

const HomeTab = ({ familyInfo }) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [leaveFamily] = useLeaveFamilyMutation();
    const [response, setResponse] = useState({ message: '', type: '' });
    const { refetch } = useGetFamilyInfoQuery();

    if (!familyInfo) {
      return <div>Loading...</div>;
    }
  
    const handleLeaveFamily = async () => {
        try {
          const result = await leaveFamily().unwrap();
          setResponse({ message: result.message, type: result.type });
          if (result.type === 'success') {
            setShowConfirmModal(false);
            refetch();
          }
        } catch (error) {
          setResponse({ message: 'Failed to leave family', type: 'error' });
        }
      };
  
    return (
      <div className="family-home">
        <div className="card mb-4">
          <div className="card-body">
            <h2 className="card-title text-center">{familyInfo.familyName}</h2>
            <hr className="my-4" />
            <p className="card-text">{familyInfo.familyMessage}</p>
          </div>
        </div>
  
        <div className="row mb-4">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Leadership</h4>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">Boss: {familyInfo.leaderName}</li>
                  <li className="list-group-item">RHM: {familyInfo.rhmName}</li>
                  <li className="list-group-item">LHM: {familyInfo.lhmName}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Information</h4>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">Total Members: {familyInfo.totalMembers}</li>
                  <li className="list-group-item">Daily Payout: £{familyInfo.dailyPayout} per member</li>
                  <li className="list-group-item">Total Businesses: {familyInfo.businessCount}</li>
                  <li className="list-group-item">Tax Rate: {familyInfo.tax}%</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
  
        <div className="card">
  <div className="card-body text-center">
    <p>It will cost £1,000,000 to leave your family.</p>
    <hr className="my-3" />
    <button className="btn btn-danger" onClick={() => setShowConfirmModal(true)}>Leave Family</button>
    </div>
</div>

{showConfirmModal && (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Leave Family</h5>
                <button type="button" className="close" onClick={() => setShowConfirmModal(false)}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to leave the family? This will cost £1,000,000.</p>
                {response.message && <Response message={response.message} type={response.type} />}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowConfirmModal(false)}>Cancel</button>
                <button type="button" className="btn btn-danger" onClick={handleLeaveFamily}>Leave Family</button>
              </div>
            </div>
          </div>
        </div>
      )}

      </div>
    );
  };

const BusinessTab = ({ familyInfo }) => {
const [selectedBusiness, setSelectedBusiness] = useState(null);
const { data: businesses, isLoading } = useGetFamilyBusinessesQuery();
const [buyBusiness] = useBuyFamilyBusinessMutation();
const [response, setResponse] = useState({ message: '', type: '' });

const handleBuyBusiness = async () => {
    if (!selectedBusiness) return;
    try {
    const result = await buyBusiness({ businessUuid: selectedBusiness }).unwrap();
    setResponse({ message: result.message, type: result.type });
    } catch (error) {
    setResponse({ message: 'Failed to buy business', type: 'error' });
    }
};

const [sellBusiness] = useSellFamilyBusinessMutation();

const handleSellBusiness = async () => {
  if (!selectedBusiness) return;
  try {
    const result = await sellBusiness({ businessUuid: selectedBusiness }).unwrap();
    setResponse({ message: result.message, type: result.type });
  } catch (error) {
    setResponse({ message: 'Failed to sell business', type: 'error' });
  }
};

const [showSellModal, setShowSellModal] = useState(false);
const [selectedBusinessDetails, setSelectedBusinessDetails] = useState(null);

const handleSellClick = () => {
  const business = businesses.find(b => b.uuid === selectedBusiness);
  setSelectedBusinessDetails(business);
  setShowSellModal(true);
};


if (isLoading) return <div>Loading businesses...</div>;

return (
    <div className="card">
    <div className="card-body">
        <h3 className="card-title">Family Businesses</h3>
        <Response message={response.message} type={response.type} />
        <table className="table">
        <thead>
            <tr>
            <th>Select</th>
            <th>Business Name</th>
            <th>Price</th>
            <th>Owned</th>
            </tr>
        </thead>
        <tbody>
            {businesses.map((business) => (
            <tr key={business.uuid}>
                <td>
                <input
                    type="radio"
                    name="businessSelect"
                    value={business.uuid}
                    checked={selectedBusiness === business.uuid}
                    onChange={() => setSelectedBusiness(business.uuid)}
                />
                </td>
                <td>{business.title}</td>
                <td>£{business.price.toLocaleString()}</td>
                <td>{business.owned}</td>
            </tr>
            ))}
        </tbody>
        </table>
        <button 
        className="btn btn-primary" 
        onClick={handleBuyBusiness}
        disabled={!selectedBusiness}
        >
        Buy Selected Business
        </button>
        {(familyInfo.userRole === 'leader' || familyInfo.userRole === 'rhm' || familyInfo.userRole === 'lhm') && (
  <button 
    className="btn btn-danger ml-2" 
    onClick={handleSellClick}
    disabled={!selectedBusiness}
  >
    Sell Selected Business
  </button>
)}
{showSellModal && selectedBusinessDetails && (
  <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Confirm Sale</h5>
          <button type="button" className="close" onClick={() => setShowSellModal(false)}>
            <span>&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>Are you sure you want to sell {selectedBusinessDetails.title}?</p>
          <p>You will receive £{selectedBusinessDetails.price / 2} back.</p>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={() => setShowSellModal(false)}>Cancel</button>
          <button type="button" className="btn btn-danger" onClick={handleSellBusiness}>Sell Business</button>
        </div>
      </div>
    </div>
  </div>
)}

    </div>
    </div>
);
};
  
const FollowersTab = ({ familyInfo }) => {
  const { data: followers } = useGetFollowersQuery();
  const [recruitFollower] = useRecruitFollowerMutation();

  const followerTypes = ['workers', 'soldiers', 'guards'];

  const handleRecruitFollower = async (type) => {
    try {
      await recruitFollower({ type });
    } catch (error) {
      console.error('Failed to recruit follower:', error);
    }
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <h3>Recruit Followers</h3>
            <div className="d-flex justify-content-between">
              {followerTypes.map((type) => (
                <img
                  key={type}
                  src={`https://placehold.co/600x400`}
                  alt={type}
                  style={{ width: '30%', cursor: 'pointer' }}
                  onDoubleClick={() => handleRecruitFollower(type)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      {(familyInfo.userRole === 'leader' || familyInfo.userRole === 'rhm' || familyInfo.userRole === 'lhm') && (
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h3>Current Followers</h3>
              <ul>
                <li>Workers: {followers?.workers || 0}</li>
                <li>Guards: {followers?.guards || 0}</li>
                <li>Soldiers: {followers?.soldiers || 0}</li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};  
  




const FamilyBarTab = () => <div>Content for Family Bar Tab</div>;
const BuildingsTab = () => <div>Content for Buildings Tab</div>;
const MassMessageTab = () => <div>Content for Mass Message Tab</div>;
const ItemFactoryTab = () => <div>Content for Item Factory Tab</div>;
const ReconTab = () => <div>Content for Recon Tab</div>;
const ProfileTab = () => <div>Content for Profile Tab</div>;
const LogsTab = () => <div>Content for Logs Tab</div>;
const ManagementTab = () => <div>Content for Management Tab</div>;

const FamilyTabs = ({ familyInfo, activeTab, handleTabClick }) => (
  <div className="family-tabs-container">
    <div className="tabs-wrapper">
      <ul className="nav nav-tabs flex-nowrap">
        {familyInfo.visibleTabs.map(tab => (
          tab.visible && (
            <li className="nav-item" key={tab.id}>
              <a 
                href={`#${tab.name.toLowerCase().replace(' ', '-')}-tab`} 
                onClick={() => handleTabClick(tab.name.toLowerCase().replace(' ', '-'))} 
                className={`nav-link ${activeTab === tab.name.toLowerCase().replace(' ', '-') ? 'active' : ''}`}
              >
                {tab.name}
              </a>
            </li>
          )
        ))}
      </ul>
    </div>
    <div className="tab-content panel rounded-0 p-3 m-0">
      <div className={`tab-pane fade ${activeTab === 'home' ? 'active show' : ''}`} id="home-tab">
        <HomeTab familyInfo={familyInfo} />
      </div>
      <div className={`tab-pane fade ${activeTab === 'business' ? 'active show' : ''}`} id="business-tab">
        <BusinessTab familyInfo={familyInfo} />
      </div>
      <div className={`tab-pane fade ${activeTab === 'followers' ? 'active show' : ''}`} id="followers-tab">
        <FollowersTab familyInfo={familyInfo}/>
      </div>
      <div className={`tab-pane fade ${activeTab === 'family-bar' ? 'active show' : ''}`} id="family-bar-tab">
        <FamilyBarTab />
      </div>
      <div className={`tab-pane fade ${activeTab === 'buildings' ? 'active show' : ''}`} id="buildings-tab">
        <BuildingsTab />
      </div>
      <div className={`tab-pane fade ${activeTab === 'mass-message' ? 'active show' : ''}`} id="mass-message-tab">
        <MassMessageTab />
      </div>
      <div className={`tab-pane fade ${activeTab === 'item-factory' ? 'active show' : ''}`} id="item-factory-tab">
        <ItemFactoryTab />
      </div>
      <div className={`tab-pane fade ${activeTab === 'recon' ? 'active show' : ''}`} id="recon-tab">
        <ReconTab />
      </div>
      <div className={`tab-pane fade ${activeTab === 'profile' ? 'active show' : ''}`} id="profile-tab">
        <ProfileTab />
      </div>
      <div className={`tab-pane fade ${activeTab === 'logs' ? 'active show' : ''}`} id="logs-tab">
        <LogsTab />
      </div>
      <div className={`tab-pane fade ${activeTab === 'management' ? 'active show' : ''}`} id="management-tab">
        <ManagementTab />
      </div>
      <div className={`tab-pane fade ${activeTab === 'members' ? 'active show' : ''}`} id="members-tab">
        <MembersTab />
      </div>
    </div>
  </div>
);


const NoFamilySection = ({ familyName, setFamilyName, handleCreateFamily, invites, alertData }) => (
    <div className="container">
      <Response message={alertData.message} type={alertData.type} />
      <CreateFamilyTab 
        familyName={familyName} 
        setFamilyName={setFamilyName} 
        handleCreateFamily={handleCreateFamily} 
      />
      <PendingInvitesTab invites={invites} />
    </div>
);

const MembersTab = () => {
    const [inviteUsername, setInviteUsername] = useState('');
    const [kickUsername, setKickUsername] = useState('');
    const [inviteResponse, setInviteResponse] = useState({ message: '', type: '' });
    const [kickResponse, setKickResponse] = useState({ message: '', type: '' });
    const [inviteUser] = useInviteUserMutation();
    const [kickUser] = useKickUserMutation();
  
    const handleInvite = async () => {
      try {
        const response = await inviteUser({ username: inviteUsername }).unwrap();
        setInviteResponse({ message: response.message, type: response.type });
        setInviteUsername('');
      } catch (error) {
        setInviteResponse({ message: 'Failed to invite user', type: 'error' });
      }
    };
  
    const handleKick = async () => {
      try {
        const response = await kickUser({ username: kickUsername }).unwrap();
        setKickResponse({ message: response.message, type: response.type });
        setKickUsername('');
      } catch (error) {
        setKickResponse({ message: 'Failed to kick user', type: 'error' });
      }
    };
  
    return (
      <div className="row">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h3>Invite User to Family</h3>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter username"
                  value={inviteUsername}
                  onChange={(e) => setInviteUsername(e.target.value)}
                />
                <button className="btn btn-primary" onClick={handleInvite}>Invite</button>
              </div>
              <Response message={inviteResponse.message} type={inviteResponse.type} />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h3>Kick User from Family</h3>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter username"
                  value={kickUsername}
                  onChange={(e) => setKickUsername(e.target.value)}
                />
                <button className="btn btn-danger" onClick={handleKick}>Kick</button>
              </div>
              <Response message={kickResponse.message} type={kickResponse.type} />
            </div>
          </div>
        </div>
      </div>
    );
};
  
const Family = () => {
    const [familyName, setFamilyName] = useState('');
    const [alertData, setAlertData] = useState({ message: '', type: '' });
    const [activeTab, setActiveTab] = useState('home');
    
    const { data: familyInfo, isLoading, refetch: refetchFamilyInfo } = useGetFamilyInfoQuery();
    const [createFamily] = useCreateFamilyMutation();
    const { data: invites } = useGetFamilyInvitesQuery();
  
    const handleCreateFamily = async () => {
      try {
        const response = await createFamily({ name: familyName }).unwrap();
        setAlertData({ message: response.message, type: response.type });
        setFamilyName('');
      } catch (error) {
        setAlertData({ message: 'Failed to create family', type: 'error' });
      }
    };
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        refetchFamilyInfo();
      };
      
    if (isLoading) return <div>Loading...</div>;
  
    if (familyInfo.family === "none") {
      return (
        <NoFamilySection 
          familyName={familyName}
          setFamilyName={setFamilyName}
          handleCreateFamily={handleCreateFamily}
          invites={invites}
          alertData={alertData}
        />
      );
    }
  
    return (
      <FamilyTabs 
        familyInfo={familyInfo}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleTabClick={handleTabClick}

      />
    );
};

export default Family;
