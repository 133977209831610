import React, { createContext, useState, useContext } from 'react';

const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({
    uuid: null,
    role: null,
    email: '',
    character_uuid: null,
    character_name: '',
    data: {
      displayName: '',
      photoURL: '',
    },
    timers: {
      crime: null,
      advCrime: null,
      gta: null,
      bta: null,
      chase: null,
      extortion: null,
      boxing: null,
      scratchcard: null
    }    
  });

  /*
  useEffect(() => {
    console.log('Current Global State:', globalState);
  }, [globalState]); // Add relevant dependencies
*/
  
  const updateGlobalState = (newState) => {
    setGlobalState(prevState => ({ ...prevState, ...newState }));
  };

  

  const updateTimer = (action, seconds) => {
    setGlobalState(prevState => {
      const futureDate = seconds > 0 ? new Date(Date.now() + seconds * 1000) : null;
      return {
        ...prevState,
        timers: {
          ...prevState.timers,
          [action]: futureDate,
        }
      };
    });
  };
  
  

  

  return (
    <GlobalStateContext.Provider value={{ globalState, updateGlobalState, updateTimer }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => useContext(GlobalStateContext);
