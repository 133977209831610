import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { selectCurrentToken, setCredentials } from "./authSlice"
import { useEffect, useState } from "react"
import { useGlobalState } from './globalState'

const RequireAuth = () => {
    const token = useSelector(selectCurrentToken)
    const location = useLocation()
    const dispatch = useDispatch()
    const { updateGlobalState } = useGlobalState()
    const [isChecking, setIsChecking] = useState(true)

    useEffect(() => {
        if (!token) {
            const storedToken = localStorage.getItem('token')
            const storedUser = JSON.parse(localStorage.getItem('user'))
            const storedCharacter = JSON.parse(localStorage.getItem('character'))
            
            if (storedToken && storedUser) {
                dispatch(setCredentials({ user: storedUser, access_token: storedToken }))
                updateGlobalState({
                    uuid: storedUser.uuid,
                    role: storedUser.role,
                    email: storedUser.email,
                    character_uuid: storedCharacter?.uuid,
                    character_name: storedCharacter?.username,
                    data: {
                        displayName: storedCharacter?.username,
                        photoURL: storedCharacter?.avatar || '',
                    }
                })
            }
        }
        setIsChecking(false)
    }, [token, dispatch, updateGlobalState])

    if (isChecking) {
        return null // or a loading spinner
    }

    return (
        token
            ? <Outlet />
            : <Navigate to="/login" state={{ from: location }} replace />
    )
}

export default RequireAuth
